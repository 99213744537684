.breadcrumbs {
  ol {
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;
    text-align: right;

    li {
      padding: 0;
      margin: 0;
      display: inline-block;
      position: relative;

      a {
        font-size: 14px;
        text-transform: lowercase;
        display: block;
        padding: 3px 5px 3px 0px;
        margin-right: 10px;

      }


      &:after {
        content: ">";
        display: block;
        position: absolute;
        right: 0px;
        top: 5px;
        font-size: 12px;
      }

      &:last-child {
        a {
          text-decoration: none;
        }

        &:after {
          display: none;
        }
      }

    }

  }
}