.page-content {
  ul.article-list {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin: 0;
      padding: 0;
      margin-bottom: 2px;

      a {
        padding: 10px;
        display: block;
        width: 100%;
        background-color: rgba(0,0,0,0.1);
        text-decoration: none;
        transition: all .2s;

        &:hover {
          padding-left: 20px;
        }
      }
    }
  }
}