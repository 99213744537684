.mr {
	width: 300px;
	background-color: #EEEEEE;
	min-height: 800px;
	@media(max-width: 1100px) {
		display: none;
	}

}

.mr-mobile {
	width: 100%;
	background-color: #EEEEEE;
	min-height: 100px;

	@media(min-width: 1100px) {
		display: none;
	}
}