.index-header-block {
	background-image: url("../img/afina.png");
	background-repeat: no-repeat;
	background-position: left bottom 4px;
	background-size: auto 200px;
	padding-left: 140px;
	font-style: italic;
	font-size: 14px;
	margin-bottom: 20px;

	@media(max-width: 500px) {
		background-position: top center;
		padding-left: 0px;
		padding-top: 215px;
	}
}
