.topmenu {
  background-color: $greekColor;
  color: #fff;
  height: 50px;
  position: relative;
  z-index: 100;

  .wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto auto;

    .button-panel {
      width: 50px;

      &.open {
        @media(min-width: 560px) {
          width: 220px;
        }
      }


      .bars {
        display: block;
        width: 50px;
        height: 50px;
        padding: 10px;
        right: 5px;
        top: 20px;

        i {
          display: block;
          height: 2px;
          background-color: #fff;
          width: 30px;
          margin-top: 5px;
          margin-bottom: 5px;
          position: absolute;
          left: 10px;
          transition: all 0.2s;

          &:nth-child(1) {
            top: 11px;
          }
          &:nth-child(2) {
            top: 18px;
          }
          &:nth-child(3) {
            top: 25px;
          }
        }
      }

      .bars.right {
        top: 5px;
        left: 0px;
      }
    }

    .search-panel {
      padding-top: 10px;
      padding-right: 50px;
      position: relative;

      input[type=search] {
        width: 100%;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        padding: 5px 10px;
        color: #555;
        font-style: italic;

        &:focus {
          outline: none;
        }
      }

      input[type=submit] {
        position: absolute;
        right: 20px;
        top: 10px;
        background-color: rgba(0,0,0,0.1);
        width: 30px;
        height: 30px;
        border: none;
        outline: none;
        color: transparent;
        font-size: 0;
        padding: 0;
        margin: 0;
        background-image: url("img/search.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 24px 24px;
        cursor: pointer;

        &:hover {
          background-color: rgba(255,255,255,0.1);
        }
      }

      .preloader {
        position: absolute;
        right: 55px;
        height: 30px;
        width: 30px;
        background-image: url("./img/preloader.svg");
        background-position: center center;
        background-size: 20px 20px;
        top: 10px;
        background-repeat: no-repeat;
        display: none;

        &.visible {
          display: block;
        }
      }


      .search-results {

        position: absolute;
        top: 39px;
        left: 1px;
        right: 51px;
        background-color: #ddd;
        display: none;
        z-index: 100;

        a {
          display: block;
          padding: 5px 10px 5px 20px;
          border-bottom: 1px solid #fff;
          font-size: 14px;
          text-decoration: none;
          position: relative;

          i {
            position: absolute;
            font-size: 12px;
            right: 2px;
            bottom: 2px;
            color: #333;
          }
        }

        &.visible {
          display: block;
        }
      }


    }

    .contrast-panel {
      width: 50px;
      padding-top: 10px;

      a {
        display: inline-block;
        background-image: url("img/contrast.svg");
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 18px 18px;
        background-color: rgba(0,0,0,0.1);
        border-radius: 4px;
        transition: all 0.2s;

        &:hover {
          text-decoration: none;
          background-color: rgba(255,255,255,0.1);
        }
      }
    }





    .language-panel {
      height: 50px;
      text-align: right;
      padding-right: 20px;

      .language-button  {
        height: 50px;
        background-color: rgba(0,0,0,0.1);
        padding: 5px 10px;
        border-radius: 4px;
        transition: all 0.2s;

        &:hover {
          text-decoration: none;
          background-color: rgba(255,255,255,0.1);
        }

        .icon {
          height: 50px;
          line-height: 50px;
        }

        .text {
          height: 50px;
          line-height: 50px;
          color: #fff;
          text-decoration: none;
          font-size: 14px;
          padding-right: 10px;

          @media(max-width: 800px) {
            display: none;
          }
        }
      }
    }
  }

  .language-panel-popup {
    position: absolute;
    z-index: 100;
    right: 0;
    top: 50px;
    background-color: $greekColor;
    padding: 10px 20px 10px 10px;
    transform-origin: top;
    transform: scaleY(0);
    transition: all 0.2s;

    &.visible {
      transform: scaleY(1);
    }

    a {

      display: block;
      height: 35px;
      padding: 4px 10px;
      text-align: right;
      line-height: 30px;
      border-radius: 4px;

      &:hover {
        background-color: rgba(255,255,255,0.1);
        text-decoration: none;
      }

      .icon {
      }

      .text {
        height: 50px;
        line-height: 30px;
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        padding-right: 10px;
      }
    }

  }
}

.desktop .topmenu .wrapper .button-panel .bars:hover {

  i:first-child {
    transform: rotate(-30deg);
    width: 20px;
    left: 10px;
    top: 13px;
  }

  i:last-child {
    transform: rotate(30deg);
    width: 20px;
    left: 10px;
    top: 23px;
  }
}

.desktop .topmenu .wrapper .button-panel .bars.right:hover {

  i:first-child {
    transform: rotate(30deg);
    width: 20px;
    left: 20px;
    top: 13px;
  }

  i:last-child {
    transform: rotate(-30deg);
    width: 20px;
    left: 20px;
    top: 23px;
  }
}