.login-page {

  form {
    width: 100%;
    max-width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    margin: 50px auto 0;
    background-color: #eee;
  }

  h1 {
    font-size: 24px;
    margin: 0 0 10px;
  }

  input, label {
    display: block;
    width: 100%;
  }

  label {
    font-size: 14px;
  }

  input {
    font-size: 16px;
    margin-bottom: 10px;
    padding: 3px 10px;
  }

}