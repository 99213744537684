.error-note {
  max-width: 460px;
  background-color: #eee;
  border: 1px solid #ddd;
  color: #777;
  font-size: 0.8em;
  padding: 40px 20px;
  margin-top: 40px;
  border-radius: 50%;
  text-align: center;
}

.error-image {
  width: 100%;
  max-width: 460px;
}