.page-content {
  padding: 15px;
  position: relative;
  background-color: #fff;

  .language-panel {
    position: absolute; 
    right: 0px; 
    top: 0px; 
    display: inline-block;
    background-color: #eee;
    padding: 4px;

    a {
      font-size: 16px;
      color: #000;
      text-decoration: none;
      display: inline-block;
      margin-right: 5px;

      &:hover {
        opacity: 0.3;
      }
    }
  }
}

body.dark {
  .page-content {
    background-color: #161616;
  }
}