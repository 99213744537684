.constellations-table {
  border-collapse: collapse;
  td, th {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  th {
    background-color: #eee;
    cursor: pointer;
    padding: 10px 10px;
    font-weight: normal;
    text-align: left;

    &:hover {
      background-color: #ddd;
    }
  }
}

body.dark {
  .constellations-table {
    th {
      background-color: #444;

      &:hover {
        background-color: #666;
      }
    }
  }
}

.const-info {
  td, th {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
}

.constellation-block {

  display: grid;
  grid-template-columns: 250px auto;
  grid-column-gap: 30px;

  @media(max-width: 900px) {
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
  }

  .const-img {
    width: 250px;
  }

}
