.glossary-container {
  display: grid;
  grid-template-columns: 1fr 300px;

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
  }

  h1 {
    margin-bottom: 0;
  }

  input {
    border: none;
    outline: none;
    border-bottom: 1px solid #777;
    font-size: 18px;
    background-color: transparent;
    margin-top: 30px;
    margin-bottom: 0;
    width: 100%;
    color: #777;

    &::placeholder {
      color: #777;
    }

  }
}

ul.glossary-list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 0;
    margin: 0;
    list-style: none;

    &.hide {
      display: none;
    }

    .first-letter {
      border-bottom: 1px solid #444;
      margin: 30px 0 5px 0;
      padding: 10px 0 10px 40px;
      font-size: 18px;
      font-weight: bold;
    }
    ul {
      -webkit-columns: 150px 5;
      -moz-columns: 150px 5;
      columns: 150px 5;
      -webkit-column-gap: 30px;
      -moz-column-gap: 30px;
      column-gap: 30px;

      li {
        a {
          display: block;
          padding: 4px 0;

          &.hide {
            display: none;
          }
        }
      }
    }
  }
}

.person-parameters {
  border-collapse: collapse;
  td, th {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  th {
    background-color: #eee;
    cursor: pointer;
    padding: 10px 10px;
    font-weight: normal;
    text-align: left;

    &:hover {
      background-color: #ddd;
    }
  }
}

.person-info {
  margin-top: 20px;

  img {
    max-width: 100px;
  }

}