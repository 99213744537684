.search-list {
  padding: 0;
  list-style: none;
  margin: 0 0 20px;

  li {
    margin: 0;
    padding: 0;
    margin-bottom: 2px;
    position: relative;

    a {
      padding: 10px;
      display: block;
      width: 100%;
      background-color: rgba(0,0,0,0.1);
      text-decoration: none;
      transition: all .2s;

      &:hover {
        padding-left: 20px;
      }

      i {
        position: absolute;
        right: 4px;
        bottom: 2px;
        font-size: 13px;
        color: #444;
      }
    }
  }
}

.search-info {

  display: grid;
  grid-template-columns: 1fr auto;

  .search-links {
    a, span, i {
      display: inline-block;
      font-style: normal;
      width: 30px;
      height: 30px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      border:1px solid #aaa;
      line-height: 30px;
      text-align: center;
      vertical-align: top;

      &.first {
        background-image: url("../img/angles-left.svg");
      }
      &.prev {
        background-image: url("../img/angle-left.svg");
      }
      &.next {
        background-image: url("../img/angle-left.svg");
        transform: rotate(180deg);
      }
      &.last {
        background-image: url("../img/angles-left.svg");
        transform: rotate(180deg);
      }
    }

    i {
      border: 1px solid transparent;
      width: auto;
      font-size: 14px;
    }

    a:hover {
      background-color: rgba(0,0,0,0.1);
    }

    span {
      opacity: 0.3;
    }

  }
  .search-counters {
    height: 30px;
    border:1px solid #aaa;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
    font-size: 14px;
  }
}

body.dark {
  .search-info {
    .first {
      background-image: url("../img/angles-left-white.svg");
    }
    .prev {
      background-image: url("../img/angle-left-white.svg");
    }
    .next {
      background-image: url("../img/angle-left-white.svg");
      transform: rotate(180deg);
    }
    .last {
      background-image: url("../img/angles-left-white.svg");
      transform: rotate(180deg);
    }
    a:hover {
      background-color: rgba(255,255,255,0.1);
    }
  }
}