article {

  figure.image
  {
    width: auto;
    display: inline-block;
    cursor: pointer;

    @media(max-width: 500px) {
      float: none !important;
      margin-right: 0;
    }


    &.align-right
    {
      float: right;
    }

    &.align-left
    {
      float: left;
    }

    img
    {
      clear: both !important;
      float: none !important;
    }

    figcaption
    {
      font-size: 14px;
      padding: 10px 10px 10px 10px;
      background-color: #eee;
      position: relative;
      top: -20px;
      left: -20px;
      right: -40px;
      width: auto;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow:    0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      box-shadow:         0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      max-width: 250px;
      hyphens: auto;

      br {
        display: none;
      }
    }
  }

  hr {
    display: block;
    float: none;
    clear: both;
    opacity: 0.1;
  }


  .side-articles {

    padding-top: 40px;
    display: block;
    text-align: center;

    .prev, .next {
      margin-bottom: 10px;
      border-radius: 4px;
      max-width: 300px;
      width: 100%;

      display: inline-block;
      background-color: rgba(0,0,0,0.1);
      text-decoration: none;
      transition: all .2s;
      position: relative;
      padding: 10px;

      &:hover {
        padding-left: 20px;
      }

      &:after {
        content: "";
        background-image: url("img/angle-left.svg");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        position: absolute;
        left: 5px;
        height: 40px;
        width: 40px;
        top: 50%;
        margin-top: -20px;
        opacity: 0.1;
      }

      &.next {

        &:after {
          right: 5px;
          left: auto;
          transform: rotate(180deg);
        }
      }

    }
  }
}

body.dark {
  article {
    figure.image {
      figcaption {
        background-color: #555;
      }
    }
  }

  .side-articles {
    .prev, .next {
      background-color: rgba(255,255,255,0.1);
      &:after {
        background-image: url("img/angle-left-white.svg");
      }
    }
  }
}