nav {
	background-color: #eee;
	position: relative;
	width: 220px;
	padding: 10px 10px 10px 20px;
	transition: all 0.2s;
	overflow: hidden;


	@media(max-width: 800px) {
		position: absolute;
		left: 0px;
		top: 50px;
		z-index: 100;
	}


	ul, li {
		display: block;
		padding: 0;
		margin: 0;
	}

	& > ul {

		width: 190px;

		& > li {
			& > a {
				font-family: 'Vollkorn', serif;
				font-size: 20px;
				color: $mainColor;
				display: block;
				padding-left: 45px;
				background-repeat: no-repeat;
				min-height: 60px;
				padding-top: 20px;
				text-decoration: none;
				background-size: 38px 52px;
			}

			&.main > a {
				background-position: left center;
				background-image: url("img/menu/menu0.png");
			}
			&.mythology > a {
				background-position: left center;
				background-image: url("img/menu/menu1.png");
			}
			&.history > a {
				background-position: left center;
				background-image: url("img/menu/menu2.png");
			}
			&.art > a {
				background-position: left center;
				background-image: url("img/menu/menu3.png");
			}
			&.stars > a {
				background-position: left center;
				background-image: url("img/menu/menu5.png");
			}

			& > ul {
				padding-bottom: 5px;

				& > li {
					& > a {
						font-size: 14px;
						padding: 0.3em 0 0.3em 45px;
						display: block;
						text-decoration: none;
						line-height: 16px;
					}
				}
			}
		}
	}


	&.hidden {

		padding-left: 0;
		padding-right: 0;
		width: 0px;
		padding-top: 10px;


		/*
		& > ul {
			& > li {
				position: relative;
				width: 45px;

				& > ul {
					position: absolute;
					left: 45px;
					top: 0px;
					width: 150px;

					background-color: #ddd;
					padding: 10px;
					transform: scaleX(0);
					transform-origin: left;

					a {
						padding-left: 20px;
					}
				}

				& > a {

					width: 40px;
					overflow: hidden;

				}

				&.open {
					background-color: #ddd;

					& > ul {
						transform: scaleX(1);
					}
				}

			}
		}
		*/


	}

	&.hidden.animated-submenu > ul > li > ul {
		transition: all 0.2s;
	}
}


body.dark {
	nav {
		background-color: #444;

		& > ul > li > a {
			color: #fff;
		}
	}
}