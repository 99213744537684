.starmap-page {

  .starmap
  {
    max-width: 860px;
    max-height: 860px;
    width: 100%;
    height: 100%;
    display: block;
    margin: 20px auto 20px 0;
  }

  map area:hover
  {
    background-color: red;
  }
}