ul.sitemap {

  display: block;
  list-style: none;
  padding: 0;
  margin: 0;

  ul, li {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-size: 12px;
  }

  ul {
    padding-left: 20px;
  }

  & > li {
    font-size: 20px;

    & > ul {
      & > li {
        font-size: 18px;

        & > ul {
          & > li {
            font-size: 14px;


          }
        }

      }
    }
  }

  li.switch {
  }
}