ul.media-category-list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  display: block;

  li {
    display: inline-block;
    width: 100%;
    max-width: 240px;
    vertical-align: top;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;

        figure {
          img {
            transform: scale(110%);
          }
        }
      }
    }

    figure {
      width: 200px;
      height: 280px;
      margin: 40px 30px 70px 30px;
      position: relative;

      img {
        width: 200px;
        height: 280px;
        object-fit: cover;
        transition: all 0.2s;
      }

      figcaption {
        padding: 10px 10px 10px 10px;
        position: relative;
        top: -20px;
        left: -20px;
        right: -40px;
        width: auto;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        max-width: 250px;
        hyphens: auto;
        font-size: 16px;
        font-weight: bold;
        background-color: #eee;
        color: #111;

        i {
          display: block;
          font-weight: normal;
          font-size: 14px;

        }
      }
    }


  }
}

body.dark {
  ul.media-category-list li {
    figure {
      figcaption {
        background-color: #555;
        color: #fff;
      }
    }
  }
}