$background: #ddd;
$mainColor: #111;
$refColor: #055EB0;
$greekColor: #055EB0;
$refHover: #e6007e;

$mainColorContrast: #fff;
$backgroundContrast: #444;
$refColorContrast: #a5d4ff;
$greekColorContrast: #a5d4ff;
$refHoverContrast: #e674b3;

* {
	box-sizing: border-box;
}

html {
	height: 100%;
	width: 100%;
	min-height: 100%;
}

.outer-wrapper {
	max-width: 2300px;
	margin: 0 auto;
}


.up-button {
	position: fixed;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	bottom: 30px;
	right: 10px;
	background-color: $greekColor;
	color: #fff;
	text-align: center;
	line-height: 40px;
	text-decoration: none;
	border: 2px solid #fff;
	transition: all 0.3s;
	display: block;

	&.hidden {
		transform: scale(0);
	}

	svg {
		width: 17px;
		height: 17px;
		position: absolute;
		top: 10px;
		left: 10px;
		fill: #fff;
	}

	&:hover {
		border: 2px solid $greekColor;
		background-color: #fff;

		svg {
			fill: $greekColor;
		}
	}
}

body {
	background-color: $background;
	color: $mainColor;
	padding: 0;
	margin: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	height: 100%;
	font-family: 'Noto Sans', sans-serif;

	&.dark {
		color: $mainColorContrast;

		a {
			color: $refColorContrast;

			&:hover {
				color: $refHoverContrast;
			}
		}
	}
}

h1 {
	font-family: 'Vollkorn', serif;
	font-size: 32px;
}
h2 {
	font-family: 'Vollkorn', serif;
	font-size: 24px;
}

a {
	color: $refColor;

	&:hover {
		color: $refHover;
	}
}



.page {

	display: grid;
	grid-template-columns: auto 1fr auto;
	height: 100%;

	@media(max-width: 1100px) {
		grid-template-columns: auto 1fr;
		grid-template-areas: "nav page" "mr mr";

		.left-menu {
			grid-area: nav;
		}

		.page-content {
			grid-area: page;
		}

		.mr {
			grid-area: mr;

			width: 100%;
			background-color: #EEEEEE;
			min-height: 200px;
		}
	}
}

.overlay {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.4);
	z-index: 50;
	display: none;

	&.visible {
		display: block;
	}
}