/* cyrillic */
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Noto Sans Italic"), local("NotoSans-Italic"), url(fonts/note_sans_italic_400_cyrillic.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Noto Sans Italic"), local("NotoSans-Italic"), url(fonts/note_sans_italic_400_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Noto Sans Bold Italic"), local("NotoSans-BoldItalic"), url(fonts/note_sans_italic_700_cyrillic.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Noto Sans Bold Italic"), local("NotoSans-BoldItalic"), url(fonts/note_sans_italic_700_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Noto Sans"), local("NotoSans"), url(fonts/note_sans_normal_400_cyrillic.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Noto Sans"), local("NotoSans"), url(fonts/note_sans_normal_400_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Noto Sans Bold"), local("NotoSans-Bold"), url(fonts/note_sans_normal_700_cyrillic.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Noto Sans Bold"), local("NotoSans-Bold"), url(fonts/note_sans_normal_700_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: 600;
  src: local("Vollkorn SemiBold Italic"), local("Vollkorn-SemiBoldItalic"), url(fonts/vollkorn_italic_600_cyrillic.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: 600;
  src: local("Vollkorn SemiBold Italic"), local("Vollkorn-SemiBoldItalic"), url(fonts/vollkorn_italic_600_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  min-height: 100%;
}

.outer-wrapper {
  max-width: 2300px;
  margin: 0 auto;
}

.up-button {
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: 30px;
  right: 10px;
  background-color: #055EB0;
  color: #fff;
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  border: 2px solid #fff;
  transition: all 0.3s;
  display: block;
}
.up-button.hidden {
  transform: scale(0);
}
.up-button svg {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 10px;
  left: 10px;
  fill: #fff;
}
.up-button:hover {
  border: 2px solid #055EB0;
  background-color: #fff;
}
.up-button:hover svg {
  fill: #055EB0;
}

body {
  background-color: #ddd;
  color: #111;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  font-family: "Noto Sans", sans-serif;
}
body.dark {
  color: #fff;
}
body.dark a {
  color: #a5d4ff;
}
body.dark a:hover {
  color: #e674b3;
}

h1 {
  font-family: "Vollkorn", serif;
  font-size: 32px;
}

h2 {
  font-family: "Vollkorn", serif;
  font-size: 24px;
}

a {
  color: #055EB0;
}
a:hover {
  color: #e6007e;
}

.page {
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 100%;
}
@media (max-width: 1100px) {
  .page {
    grid-template-columns: auto 1fr;
    grid-template-areas: "nav page" "mr mr";
  }
  .page .left-menu {
    grid-area: nav;
  }
  .page .page-content {
    grid-area: page;
  }
  .page .mr {
    grid-area: mr;
    width: 100%;
    background-color: #EEEEEE;
    min-height: 200px;
  }
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
  display: none;
}
.overlay.visible {
  display: block;
}

.topmenu {
  background-color: #055EB0;
  color: #fff;
  height: 50px;
  position: relative;
  z-index: 100;
}
.topmenu .wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
}
.topmenu .wrapper .button-panel {
  width: 50px;
}
@media (min-width: 560px) {
  .topmenu .wrapper .button-panel.open {
    width: 220px;
  }
}
.topmenu .wrapper .button-panel .bars {
  display: block;
  width: 50px;
  height: 50px;
  padding: 10px;
  right: 5px;
  top: 20px;
}
.topmenu .wrapper .button-panel .bars i {
  display: block;
  height: 2px;
  background-color: #fff;
  width: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: absolute;
  left: 10px;
  transition: all 0.2s;
}
.topmenu .wrapper .button-panel .bars i:nth-child(1) {
  top: 11px;
}
.topmenu .wrapper .button-panel .bars i:nth-child(2) {
  top: 18px;
}
.topmenu .wrapper .button-panel .bars i:nth-child(3) {
  top: 25px;
}
.topmenu .wrapper .button-panel .bars.right {
  top: 5px;
  left: 0px;
}
.topmenu .wrapper .search-panel {
  padding-top: 10px;
  padding-right: 50px;
  position: relative;
}
.topmenu .wrapper .search-panel input[type=search] {
  width: 100%;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  padding: 5px 10px;
  color: #555;
  font-style: italic;
}
.topmenu .wrapper .search-panel input[type=search]:focus {
  outline: none;
}
.topmenu .wrapper .search-panel input[type=submit] {
  position: absolute;
  right: 20px;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  color: transparent;
  font-size: 0;
  padding: 0;
  margin: 0;
  background-image: url("img/search.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px 24px;
  cursor: pointer;
}
.topmenu .wrapper .search-panel input[type=submit]:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.topmenu .wrapper .search-panel .preloader {
  position: absolute;
  right: 55px;
  height: 30px;
  width: 30px;
  background-image: url("./img/preloader.svg");
  background-position: center center;
  background-size: 20px 20px;
  top: 10px;
  background-repeat: no-repeat;
  display: none;
}
.topmenu .wrapper .search-panel .preloader.visible {
  display: block;
}
.topmenu .wrapper .search-panel .search-results {
  position: absolute;
  top: 39px;
  left: 1px;
  right: 51px;
  background-color: #ddd;
  display: none;
  z-index: 100;
}
.topmenu .wrapper .search-panel .search-results a {
  display: block;
  padding: 5px 10px 5px 20px;
  border-bottom: 1px solid #fff;
  font-size: 14px;
  text-decoration: none;
  position: relative;
}
.topmenu .wrapper .search-panel .search-results a i {
  position: absolute;
  font-size: 12px;
  right: 2px;
  bottom: 2px;
  color: #333;
}
.topmenu .wrapper .search-panel .search-results.visible {
  display: block;
}
.topmenu .wrapper .contrast-panel {
  width: 50px;
  padding-top: 10px;
}
.topmenu .wrapper .contrast-panel a {
  display: inline-block;
  background-image: url("img/contrast.svg");
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px 18px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all 0.2s;
}
.topmenu .wrapper .contrast-panel a:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}
.topmenu .wrapper .language-panel {
  height: 50px;
  text-align: right;
  padding-right: 20px;
}
.topmenu .wrapper .language-panel .language-button {
  height: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.2s;
}
.topmenu .wrapper .language-panel .language-button:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}
.topmenu .wrapper .language-panel .language-button .icon {
  height: 50px;
  line-height: 50px;
}
.topmenu .wrapper .language-panel .language-button .text {
  height: 50px;
  line-height: 50px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  padding-right: 10px;
}
@media (max-width: 800px) {
  .topmenu .wrapper .language-panel .language-button .text {
    display: none;
  }
}
.topmenu .language-panel-popup {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 50px;
  background-color: #055EB0;
  padding: 10px 20px 10px 10px;
  transform-origin: top;
  transform: scaleY(0);
  transition: all 0.2s;
}
.topmenu .language-panel-popup.visible {
  transform: scaleY(1);
}
.topmenu .language-panel-popup a {
  display: block;
  height: 35px;
  padding: 4px 10px;
  text-align: right;
  line-height: 30px;
  border-radius: 4px;
}
.topmenu .language-panel-popup a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}
.topmenu .language-panel-popup a .text {
  height: 50px;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  padding-right: 10px;
}

.desktop .topmenu .wrapper .button-panel .bars:hover i:first-child {
  transform: rotate(-30deg);
  width: 20px;
  left: 10px;
  top: 13px;
}
.desktop .topmenu .wrapper .button-panel .bars:hover i:last-child {
  transform: rotate(30deg);
  width: 20px;
  left: 10px;
  top: 23px;
}

.desktop .topmenu .wrapper .button-panel .bars.right:hover i:first-child {
  transform: rotate(30deg);
  width: 20px;
  left: 20px;
  top: 13px;
}
.desktop .topmenu .wrapper .button-panel .bars.right:hover i:last-child {
  transform: rotate(-30deg);
  width: 20px;
  left: 20px;
  top: 23px;
}

nav {
  background-color: #eee;
  position: relative;
  width: 220px;
  padding: 10px 10px 10px 20px;
  transition: all 0.2s;
  overflow: hidden;
}
@media (max-width: 800px) {
  nav {
    position: absolute;
    left: 0px;
    top: 50px;
    z-index: 100;
  }
}
nav ul, nav li {
  display: block;
  padding: 0;
  margin: 0;
}
nav > ul {
  width: 190px;
}
nav > ul > li > a {
  font-family: "Vollkorn", serif;
  font-size: 20px;
  color: #111;
  display: block;
  padding-left: 45px;
  background-repeat: no-repeat;
  min-height: 60px;
  padding-top: 20px;
  text-decoration: none;
  background-size: 38px 52px;
}
nav > ul > li.main > a {
  background-position: left center;
  background-image: url("img/menu/menu0.png");
}
nav > ul > li.mythology > a {
  background-position: left center;
  background-image: url("img/menu/menu1.png");
}
nav > ul > li.history > a {
  background-position: left center;
  background-image: url("img/menu/menu2.png");
}
nav > ul > li.art > a {
  background-position: left center;
  background-image: url("img/menu/menu3.png");
}
nav > ul > li.stars > a {
  background-position: left center;
  background-image: url("img/menu/menu5.png");
}
nav > ul > li > ul {
  padding-bottom: 5px;
}
nav > ul > li > ul > li > a {
  font-size: 14px;
  padding: 0.3em 0 0.3em 45px;
  display: block;
  text-decoration: none;
  line-height: 16px;
}
nav.hidden {
  padding-left: 0;
  padding-right: 0;
  width: 0px;
  padding-top: 10px;
  /*
  & > ul {
  	& > li {
  		position: relative;
  		width: 45px;

  		& > ul {
  			position: absolute;
  			left: 45px;
  			top: 0px;
  			width: 150px;

  			background-color: #ddd;
  			padding: 10px;
  			transform: scaleX(0);
  			transform-origin: left;

  			a {
  				padding-left: 20px;
  			}
  		}

  		& > a {

  			width: 40px;
  			overflow: hidden;

  		}

  		&.open {
  			background-color: #ddd;

  			& > ul {
  				transform: scaleX(1);
  			}
  		}

  	}
  }
  */
}
nav.hidden.animated-submenu > ul > li > ul {
  transition: all 0.2s;
}

body.dark nav {
  background-color: #444;
}
body.dark nav > ul > li > a {
  color: #fff;
}

.page-content {
  padding: 15px;
  position: relative;
  background-color: #fff;
}
.page-content .language-panel {
  position: absolute;
  right: 0px;
  top: 0px;
  display: inline-block;
  background-color: #eee;
  padding: 4px;
}
.page-content .language-panel a {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  display: inline-block;
  margin-right: 5px;
}
.page-content .language-panel a:hover {
  opacity: 0.3;
}

body.dark .page-content {
  background-color: #161616;
}

article figure.image {
  width: auto;
  display: inline-block;
  cursor: pointer;
}
@media (max-width: 500px) {
  article figure.image {
    float: none !important;
    margin-right: 0;
  }
}
article figure.image.align-right {
  float: right;
}
article figure.image.align-left {
  float: left;
}
article figure.image img {
  clear: both !important;
  float: none !important;
}
article figure.image figcaption {
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  background-color: #eee;
  position: relative;
  top: -20px;
  left: -20px;
  right: -40px;
  width: auto;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  max-width: 250px;
  hyphens: auto;
}
article figure.image figcaption br {
  display: none;
}
article hr {
  display: block;
  float: none;
  clear: both;
  opacity: 0.1;
}
article .side-articles {
  padding-top: 40px;
  display: block;
  text-align: center;
}
article .side-articles .prev, article .side-articles .next {
  margin-bottom: 10px;
  border-radius: 4px;
  max-width: 300px;
  width: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: all 0.2s;
  position: relative;
  padding: 10px;
}
article .side-articles .prev:hover, article .side-articles .next:hover {
  padding-left: 20px;
}
article .side-articles .prev:after, article .side-articles .next:after {
  content: "";
  background-image: url("img/angle-left.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  position: absolute;
  left: 5px;
  height: 40px;
  width: 40px;
  top: 50%;
  margin-top: -20px;
  opacity: 0.1;
}
article .side-articles .prev.next:after, article .side-articles .next.next:after {
  right: 5px;
  left: auto;
  transform: rotate(180deg);
}

body.dark article figure.image figcaption {
  background-color: #555;
}
body.dark .side-articles .prev, body.dark .side-articles .next {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark .side-articles .prev:after, body.dark .side-articles .next:after {
  background-image: url("img/angle-left-white.svg");
}

.mr {
  width: 300px;
  background-color: #EEEEEE;
  min-height: 800px;
}
@media (max-width: 1100px) {
  .mr {
    display: none;
  }
}

.mr-mobile {
  width: 100%;
  background-color: #EEEEEE;
  min-height: 100px;
}
@media (min-width: 1100px) {
  .mr-mobile {
    display: none;
  }
}

.index-header-block {
  background-image: url("../img/afina.png");
  background-repeat: no-repeat;
  background-position: left bottom 4px;
  background-size: auto 200px;
  padding-left: 140px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  .index-header-block {
    background-position: top center;
    padding-left: 0px;
    padding-top: 215px;
  }
}

.constellations-table {
  border-collapse: collapse;
}
.constellations-table td, .constellations-table th {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
.constellations-table th {
  background-color: #eee;
  cursor: pointer;
  padding: 10px 10px;
  font-weight: normal;
  text-align: left;
}
.constellations-table th:hover {
  background-color: #ddd;
}

body.dark .constellations-table th {
  background-color: #444;
}
body.dark .constellations-table th:hover {
  background-color: #666;
}

.const-info td, .const-info th {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.constellation-block {
  display: grid;
  grid-template-columns: 250px auto;
  grid-column-gap: 30px;
}
@media (max-width: 900px) {
  .constellation-block {
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
  }
}
.constellation-block .const-img {
  width: 250px;
}

.error-note {
  max-width: 460px;
  background-color: #eee;
  border: 1px solid #ddd;
  color: #777;
  font-size: 0.8em;
  padding: 40px 20px;
  margin-top: 40px;
  border-radius: 50%;
  text-align: center;
}

.error-image {
  width: 100%;
  max-width: 460px;
}

.glossary-container {
  display: grid;
  grid-template-columns: 1fr 300px;
}
@media (max-width: 800px) {
  .glossary-container {
    grid-template-columns: 1fr;
  }
}
.glossary-container h1 {
  margin-bottom: 0;
}
.glossary-container input {
  border: none;
  outline: none;
  border-bottom: 1px solid #777;
  font-size: 18px;
  background-color: transparent;
  margin-top: 30px;
  margin-bottom: 0;
  width: 100%;
  color: #777;
}
.glossary-container input::placeholder {
  color: #777;
}

ul.glossary-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.glossary-list li {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.glossary-list li.hide {
  display: none;
}
ul.glossary-list li .first-letter {
  border-bottom: 1px solid #444;
  margin: 30px 0 5px 0;
  padding: 10px 0 10px 40px;
  font-size: 18px;
  font-weight: bold;
}
ul.glossary-list li ul {
  -webkit-columns: 150px 5;
  -moz-columns: 150px 5;
  columns: 150px 5;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}
ul.glossary-list li ul li a {
  display: block;
  padding: 4px 0;
}
ul.glossary-list li ul li a.hide {
  display: none;
}

.person-parameters {
  border-collapse: collapse;
}
.person-parameters td, .person-parameters th {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
.person-parameters th {
  background-color: #eee;
  cursor: pointer;
  padding: 10px 10px;
  font-weight: normal;
  text-align: left;
}
.person-parameters th:hover {
  background-color: #ddd;
}

.person-info {
  margin-top: 20px;
}
.person-info img {
  max-width: 100px;
}

.login-page form {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  margin: 50px auto 0;
  background-color: #eee;
}
.login-page h1 {
  font-size: 24px;
  margin: 0 0 10px;
}
.login-page input, .login-page label {
  display: block;
  width: 100%;
}
.login-page label {
  font-size: 14px;
}
.login-page input {
  font-size: 16px;
  margin-bottom: 10px;
  padding: 3px 10px;
}

.search-list {
  padding: 0;
  list-style: none;
  margin: 0 0 20px;
}
.search-list li {
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
  position: relative;
}
.search-list li a {
  padding: 10px;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: all 0.2s;
}
.search-list li a:hover {
  padding-left: 20px;
}
.search-list li a i {
  position: absolute;
  right: 4px;
  bottom: 2px;
  font-size: 13px;
  color: #444;
}

.search-info {
  display: grid;
  grid-template-columns: 1fr auto;
}
.search-info .search-links a, .search-info .search-links span, .search-info .search-links i {
  display: inline-block;
  font-style: normal;
  width: 30px;
  height: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: 1px solid #aaa;
  line-height: 30px;
  text-align: center;
  vertical-align: top;
}
.search-info .search-links a.first, .search-info .search-links span.first, .search-info .search-links i.first {
  background-image: url("../img/angles-left.svg");
}
.search-info .search-links a.prev, .search-info .search-links span.prev, .search-info .search-links i.prev {
  background-image: url("../img/angle-left.svg");
}
.search-info .search-links a.next, .search-info .search-links span.next, .search-info .search-links i.next {
  background-image: url("../img/angle-left.svg");
  transform: rotate(180deg);
}
.search-info .search-links a.last, .search-info .search-links span.last, .search-info .search-links i.last {
  background-image: url("../img/angles-left.svg");
  transform: rotate(180deg);
}
.search-info .search-links i {
  border: 1px solid transparent;
  width: auto;
  font-size: 14px;
}
.search-info .search-links a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.search-info .search-links span {
  opacity: 0.3;
}
.search-info .search-counters {
  height: 30px;
  border: 1px solid #aaa;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 30px;
  font-size: 14px;
}

body.dark .search-info .first {
  background-image: url("../img/angles-left-white.svg");
}
body.dark .search-info .prev {
  background-image: url("../img/angle-left-white.svg");
}
body.dark .search-info .next {
  background-image: url("../img/angle-left-white.svg");
  transform: rotate(180deg);
}
body.dark .search-info .last {
  background-image: url("../img/angles-left-white.svg");
  transform: rotate(180deg);
}
body.dark .search-info a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.page-content ul.article-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.page-content ul.article-list li {
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
}
.page-content ul.article-list li a {
  padding: 10px;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: all 0.2s;
}
.page-content ul.article-list li a:hover {
  padding-left: 20px;
}

.breadcrumbs ol {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
  text-align: right;
}
.breadcrumbs ol li {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}
.breadcrumbs ol li a {
  font-size: 14px;
  text-transform: lowercase;
  display: block;
  padding: 3px 5px 3px 0px;
  margin-right: 10px;
}
.breadcrumbs ol li:after {
  content: ">";
  display: block;
  position: absolute;
  right: 0px;
  top: 5px;
  font-size: 12px;
}
.breadcrumbs ol li:last-child a {
  text-decoration: none;
}
.breadcrumbs ol li:last-child:after {
  display: none;
}

ul.media-category-list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  display: block;
}
ul.media-category-list li {
  display: inline-block;
  width: 100%;
  max-width: 240px;
  vertical-align: top;
}
ul.media-category-list li a {
  text-decoration: none;
}
ul.media-category-list li a:hover {
  text-decoration: none;
}
ul.media-category-list li a:hover figure img {
  transform: scale(110%);
}
ul.media-category-list li figure {
  width: 200px;
  height: 280px;
  margin: 40px 30px 70px 30px;
  position: relative;
}
ul.media-category-list li figure img {
  width: 200px;
  height: 280px;
  object-fit: cover;
  transition: all 0.2s;
}
ul.media-category-list li figure figcaption {
  padding: 10px 10px 10px 10px;
  position: relative;
  top: -20px;
  left: -20px;
  right: -40px;
  width: auto;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  max-width: 250px;
  hyphens: auto;
  font-size: 16px;
  font-weight: bold;
  background-color: #eee;
  color: #111;
}
ul.media-category-list li figure figcaption i {
  display: block;
  font-weight: normal;
  font-size: 14px;
}

body.dark ul.media-category-list li figure figcaption {
  background-color: #555;
  color: #fff;
}

ul.media-list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  display: block;
}
ul.media-list li {
  display: inline-block;
  width: 100%;
  max-width: 240px;
  vertical-align: top;
}
ul.media-list li a {
  text-decoration: none;
}
ul.media-list li a:hover {
  text-decoration: none;
}
ul.media-list li a:hover figure img {
  transform: scale(110%);
}
ul.media-list li figure {
  width: 200px;
  height: 280px;
  margin: 40px 30px 70px 30px;
  position: relative;
}
ul.media-list li figure img {
  width: 200px;
  height: 280px;
  object-fit: cover;
  transition: all 0.2s;
}
ul.media-list li figure figcaption {
  padding: 10px 10px 10px 10px;
  position: relative;
  top: -20px;
  left: -20px;
  right: -40px;
  width: auto;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  max-width: 250px;
  hyphens: auto;
  font-size: 14px;
  font-weight: normal;
  background-color: #eee;
  color: #111;
}
ul.media-list li figure figcaption i {
  display: block;
  font-weight: normal;
  font-size: 14px;
}

body.dark ul.media-list li figure figcaption {
  background-color: #555;
  color: #fff;
}

.media-page {
  padding-bottom: 50px;
}
.media-page figure {
  margin: 0;
}
.media-page figure img {
  width: 100%;
  display: block;
}
.media-page ul.article-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.media-page ul.article-list li {
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
}
.media-page ul.article-list li a {
  padding: 10px;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: all 0.2s;
}
.media-page ul.article-list li a:hover {
  padding-left: 20px;
}

.starmap-page .starmap {
  max-width: 860px;
  max-height: 860px;
  width: 100%;
  height: 100%;
  display: block;
  margin: 20px auto 20px 0;
}
.starmap-page map area:hover {
  background-color: red;
}

ul.sitemap {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.sitemap ul, ul.sitemap li {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.sitemap li {
  font-size: 12px;
}
ul.sitemap ul {
  padding-left: 20px;
}
ul.sitemap > li {
  font-size: 20px;
}
ul.sitemap > li > ul > li {
  font-size: 18px;
}
ul.sitemap > li > ul > li > ul > li {
  font-size: 14px;
}
.pswp__caption__center {
  background-color: #333;
  font-size: 18px;
}

.ya-share2 > div {
  text-align: center;
}